/*
  AUTHOR - KHYATI RADADIYA
  COMPANY NAME - RADIX ANALYTICS
  Version - 1
  Change log
    ---------------------------------------
    Date         Author   Comment
    ---------------------------------------
    2023-11-27	  Khyati   This is CSS file
*/

/* loader */
.loader {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999999;
  align-items: center;
  justify-content: center;
  display: flex;
}

.loader ul {
  display: flex;
}

.loader ul li {
  list-style: none;
  height: 20px;
  width: 20px;
  margin: 0 4px;
  border-radius: 50%;
  animation: bounce 1s linear infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

.loader ul li:nth-child(1) {
  animation-delay: 0.2s;
  background-color: #05e6bd;
}

.loader ul li:nth-child(2) {
  animation-delay: 0.6s;
  background-color: #fff;
}

.loader ul li:nth-child(3) {
  animation-delay: 0.1s;
  background-color: #02698a;
}

.loader ul li:nth-child(4) {
  animation-delay: 0.8s;
  background-color: #fff;
}

.loader ul li:nth-child(5) {
  animation-delay: 0.5s;
  background-color: #05e6bd;
}

.loader ul li:nth-child(6) {
  animation-delay: 0.1s;
  background-color: #fff;
}
/* loader */

/* sidebar children menu */
.submenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.submenu.expanded {
  max-height: 1000px;
}
/* sidebar children menu */

/* react prime data table */
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  padding: 0.625rem 1rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th,
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border: 1px solid #e5e7eb;
  text-align: center;
}

.dashboard-data-table .p-datatable-thead {
  border: 1px solid #e5e7eb;
}

.p-column-header-content {
  justify-content: center;
  font-size: 16px;
}

.dashboard-data-table.p-datatable.p-datatable-gridlines
  .p-datatable-thead
  > tr
  > th,
.dashboard-data-table.p-datatable.p-datatable-gridlines
  .p-datatable-tbody
  > tr
  > td {
  text-align: center;
  padding: 4px;
  font-size: 14px;
  border: none;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  min-height: 45px !important;
  box-shadow: none !important;
  border-color: #d5dae2 !important;
  padding-left: 0.5rem;
}

.css-t3ipsp-control:hover {
  border-color: #d5dae2 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.3rem 1rem;
}

.search_input_container ::placeholder {
  font-size: 12px !important;
}
/* react prime data table */

/* react date picker */
.react-datepicker-wrapper input {
  background-color: #fff !important;
  border-radius: 5px !important;
  border: none !important;
  color: white;
  margin: 0px 10px;
  text-align: center;
  width: 160px;
}

.endDatePicker {
  left: -55px !important;
}

.startDatePicker {
  left: 50px !important;
}
/* react date picker */

/* upload video/image button */
.p-button.p-button-outlined {
  background: #1b1b29ba;
  color: white;
}

.p-fileupload .p-fileupload-content {
  padding: 0px !important;
}

.p-progressbar {
  display: none;
}
/* upload video/image button */

/* branch list checkbox */
.customDropdownPanel {
  max-width: 100% !important;
  min-width: 0;
}

@media (min-width: 768px) {
  .customDropdownPanel {
    max-width: 23.33% !important;
  }
}

@media (min-width: 1024px) {
  .customDropdownPanel {
    max-width: 14.2% !important;
  }
}

.branch_item .p-checkbox .p-checkbox-box {
  background: #1b1b2940;
}

.multi_branch_container .p-multiselect-label-container .p-placeholder {
  padding: 0 !important;
}

.multi_branch_container .p-multiselect-label-container,
.p-multiselect-label {
  padding: 0.375rem 0.75rem !important;
}

.multi_branch_container .p-multiselect-label {
  padding: 0 !important;
}
/* branch list checkbox */

/* list table */
.table_container .p-column-title {
  font-size: 14px !important;
}

.table_container td {
  font-size: 14px !important;
}

.history_dropdown .p-multiselect-token-label {
  font-size: 12px !important;
}

.video_pd_form_container label,
.user_form_container label {
  font-size: 14px !important;
}

.table_container .p-datatable-header {
  background: white;
}

.video_pd_form_container ::placeholder,
.video_pd_form_container .p-dropdown-label,
.user_form_container ::placeholder,
.user_form_container .p-dropdown-label,
.user_form_container .p-multiselect-label,
.table_container ::placeholder,
.p-dropdown-items li,
.p-multiselect-items li,
.datepicker_container .p-dropdown-label {
  font-size: 14px !important;
}

.table_container .p-column-title,
.table_container td {
  white-space: nowrap;
}

.table_container .p-datatable .p-datatable-header {
  padding: 0.75rem 0rem !important;
}

.user_form_container .p-multiselect-label-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.user_form_container .p-dropdown-label,
.video_pd_form_container .p-dropdown-label,
.datepicker_container .p-dropdown-label {
  padding: 0px !important;
}

.user_form_container .p-dropdown-trigger {
  width: 30px !important;
}
/* list table */

/* branch list main chcekbox */
.p-checkbox-box {
  background: #1b1b2940;
}
/* branch list main chcekbox */

/* history table last column stick */
.table_body table th:last-child,
.table_body table tr td:last-child {
  position: absolute;
  right: 0;
  width: 105px;
  background: #f9fafb;
}

.table_body .p-datatable-wrapper {
  padding-right: 105px;
}
/* history table last column stick */

/* record video frame */
.igXuCY {
  border-radius: 8px;
  margin-right: 8px;
}

.kagvZI {
  top: 80% !important;
}

.video-recording-popup {
  width: 50%;
}

@media (max-width: 1440px) {
  .video-recording-popup {
    width: 70%;
  }
}

@media (max-width: 1000px) {
  .video-recording-popup {
    width: 50%;
  }
}
/* record video frame */

/* input fields */
[type="text"]:focus {
  --tw-ring-color: #1b1b29 !important;
  border-color: #1b1b29 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

textarea {
  resize: none !important;
}
/* input fields */
